import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { AbstractResource } from '@resources/abstract.resource';
import { DiscountResource } from '@components/discount/resources/discount.resource';
import { AbstractComponent } from '@components/generic/abstract.component';
import { AuthService } from '@services/auth.service';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { SessionHelper } from '@helpers/session.helper';
import { MarketplaceHelper } from '@helpers/MarketplaceHelper';
import { DiscountFormGeneralModel } from '@components/discount/models/discount-form-general.model';
import { takeUntil } from 'rxjs/operators';
import { ICustomer, IDiscountFormGeneralModel, IFormBody, IFormValue, IProduct } from '@components/discount/interfaces/discount-form.interface';
import { ProductResource } from '@components/product/product.resource';
import { Observable } from 'rxjs/Observable';
import { CategoryResource } from '@resources/category.resource';
import { ICategories } from '@components/categories/models';
import { CategoryTreeHelper } from '@helpers/CategoryTreeHelper';
import { ITreeCategory } from '@interfaces/ITreeCategory';
import { CustomerResource } from '@components/customer';
import { SnackbarService } from '@components/snackbar';
import { FormNotifierService } from '@services/form-notifier.service';
import { forkJoin } from 'rxjs/observable/forkJoin';
import _ = require('lodash');
import {HydraHelper} from '@helpers/HydraHelper';
import {DiscountCartResource} from '@components/discount/resources';
import {ISuperProduct} from '@models';

@Component({
  selector: 'app-discount-restrictions-form',
  template: require('./discount-restrictions-form.component.html'),
  providers: [{ provide: AbstractResource, useClass: DiscountResource }]
})
export class DiscountRestrictionsFormComponent extends AbstractComponent implements OnInit {

  @Input() public commonFields: { [keys: string]: AbstractControl };
  @Input() public readOnly: boolean;

  @Output() public validNameField: EventEmitter<any> = new EventEmitter();
  @Output() public setReadOnly: EventEmitter<any> = new EventEmitter();

  public isReadOnly = false;

  protected model: IDiscountFormGeneralModel;
  private products$: Observable<any>;
  private products: any[];
  private customers: any[];
  private categories: ITreeCategory[];
  protected form: FormGroup;
  public modalOpened: boolean = false;
  public customerUsernames: string[];
  public customerExist: ICustomer[] = [];
  public customerNotExistUsernames: string[] = [];
  public customerToAdd: any = {};
  public modalIncludedProductsOpened: boolean = false;
  public includedProductsSku: string[];
  public includedProductsExist: IProduct[] = [];
  public includedProductsNotExistSkus: string[] = [];
  public includedProductsToAdd: any = {};


  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
    private formBuilder: FormBuilder,
    private productResource: ProductResource,
    private categoryResource: CategoryResource,
    private customerResource: CustomerResource,
    private snackbar: SnackbarService,
    private formNotifier: FormNotifierService,
    private discountCartResource: DiscountCartResource,
    @Inject('DialogService') private dialog: any,
  ) {
    super($translate, authService, resource, state);
  }

  /**
   * @inheritDoc
   */
  ngOnInit(): void {
    this.products$ = this.productResource.getByCountryCodeAndSku(
      SessionHelper.getCountry().code,
      undefined,
      { 'marketplaces[]': MarketplaceHelper.getWebsiteMarketplace().code },
      { dontUseModel: true, blocking: false },
    );

    this.categoryResource.cGet({ level: 1, marketplace: MarketplaceHelper.getWebsiteMarketplace().code })
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (response: ICategories[]) => this.categories = CategoryTreeHelper.flatten(this.decodeCategories(response))
      );

      this.getCustomers();
      this.getIncludedProducts();

    this.resource.get(this.state.params.id, { formModel: DiscountFormGeneralModel })
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe((response: IDiscountFormGeneralModel) => {
        this.model = response;
        this.isReadOnly = this.model.readOnly;
        this.setReadOnly.emit(this.isReadOnly);
        this.commonFields.name.patchValue(this.model.name);
        this.commonFields.description.patchValue(this.model.description);
        this.commonFields.zendeskTicketId.patchValue(this.model.zendeskTicketId);
        this.buildForm();
      });
  }

  private buildForm(): void {
    this.form = this.formBuilder.group({
      minimumAmount: [this.model ? this.model.minimumAmount : null],
      maximumAmount: [this.model ? this.model.maximumAmount : null],
      purchaseRange: [this.model ? this.model.type.purchaseRange : null],
      minimumProduct: [this.model ? this.model.type.minimumProduct : null],
      combinable: [this.model ? this.model.combinable : false],
      excludeSales: [this.model ? this.model.excludeSales : false],
      includedProducts: [this.model ? this.model.includedProducts : null],
      excludedProducts: [this.model ? this.model.excludedProducts : null],
      includedCategories: [this.model ? this.model.includedCategories : null],
      excludedCategories: [this.model ? this.model.excludedCategories : null],
      customers: [this.model ? this.model.customers : null],
    });
    this.form.setValidators(this.checkIncludedExcludedSameTime());
  }

  public checkIncludedExcludedSameTime(): ValidatorFn {
    return (formGroup: FormGroup): ValidationErrors | null => {
      if (formGroup.get('includedProducts').value.length !== 0 && formGroup.get('excludedProducts').value.length !== 0) {
        formGroup.get('includedProducts').setErrors({ 'includedExcluded': true });
        formGroup.get('excludedProducts').setErrors({ 'includedExcluded': true });
      } else {
        formGroup.get('includedProducts').setErrors(null);
        formGroup.get('excludedProducts').setErrors(null);
      }

      if (formGroup.get('includedCategories').value.length !== 0 && formGroup.get('excludedCategories').value.length !== 0) {
        formGroup.get('includedCategories').setErrors({ 'includedExcluded': true });
        formGroup.get('excludedCategories').setErrors({ 'includedExcluded': true });
      } else {
        formGroup.get('includedCategories').setErrors(null);
        formGroup.get('excludedCategories').setErrors(null);
      }

      return null;
    };
  }

  public getIncludedExcludedRuleHelp(): any {
    /* tslint:disable:no-bitwise */
    const discountIncludeExcludeRules = (+(this.form.get('includedProducts').value.length !== 0) * 1)
      | (+(this.form.get('excludedProducts').value.length !== 0) * 2)
      | (+(this.form.get('includedCategories').value.length !== 0) * 4)
      | (+(this.form.get('excludedCategories').value.length !== 0) * 8);
    /* tslint:enable:no-bitwise */

    return [0, 1, 2, false, 3, 5, 6, false, 4, 7, 8, false, false, false, false, false][discountIncludeExcludeRules];
  }

  private decodeCategories(categories: ICategories[]): any {
    if (!categories) {
      return null;
    }

    return categories.map((category: any) => ({
      children: this.decodeCategories(category.children),
      id: category.id,
      label: category.translations[this.currentLocale] ? category.translations[this.currentLocale].name : ''
    }));
  }

  private getCustomers(query?: any): void {
    if (query) {
      this.customerUsernames = this.multipleMailsParser(query);
      if (this.customerUsernames.length <= 1) {
        query = this.customerUsernames.length === 0 ? null : this.customerUsernames[0];
      } else {
        const observables$: Observable<any>[] = [];

        for (let i = 0 ; i < this.customerUsernames.length ; i = i + 100) {
          observables$.push(this.customerResource.exists(this.customerUsernames.slice(i, i + 100))
            .pipe(takeUntil(this.destroyed$)));
        }
        forkJoin(observables$)
        .subscribe((response: any) => {
            this.customerExist = this.customerExist.concat(...response);
            this.customerToAdd = Object.assign({}, ...this.customerExist.map((customer: ICustomer) => ({ [customer.id]: true })));
            const customerExistUsernames = [...response].map((customer: ICustomer) => customer.username);
            this.customerNotExistUsernames = this.customerUsernames.filter(x => !customerExistUsernames.includes(x));
            this.modalOpened = true;
          });
        return;
      }
    }
    this.customerResource.cGet(
      { username: query ? query : null },
      { dontUseModel: true, blocking: false, isHydra: true, returnHydraMembers: true }
    )
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: any) => {
        this.customers = response.filter((customer: ICustomer) => !!customer.username);
      });
  }

  private getIncludedProducts(query?: any): void {
    if (query) {
      this.includedProductsSku = this.multipleSkusParser(query);
      if (this.includedProductsSku.length <= 1) {
        query = this.includedProductsSku.length === 0 ? null : this.includedProductsSku[0];
      } else {
        const observables$: Observable<any>[] = [];
        for (let i = 0 ; i < this.includedProductsSku.length ; i = i + 100) {
          observables$.push(this.productResource.exists(
            this.includedProductsSku.slice(i, i + 100),
            SessionHelper.getCountry().code,
            MarketplaceHelper.getWebsiteMarketplace().code
          ).pipe(takeUntil(this.destroyed$)));
        }
        forkJoin(observables$)
        .subscribe((response: any) => {
              this.includedProductsExist = this.includedProductsExist.concat(...response);
              this.includedProductsToAdd = Object.assign({}, ...this.includedProductsExist.map((product: IProduct) => ({ [product.id]: true })));
              const includedProductsExistSkus = [...response].map((product: IProduct) => product.sku);
              this.includedProductsNotExistSkus = this.includedProductsNotExistSkus.concat(
                this.includedProductsSku.filter(x => !includedProductsExistSkus.includes(x))
              );
              this.includedProductsExist.sort((a: IProduct, b: IProduct) => {
                return a.sku.localeCompare(b.sku);
              });

              this.includedProductsNotExistSkus.sort((a: string, b: string) => {
                return a.localeCompare(b);
              });
              this.includedProductsNotExistSkus = _.uniq(this.includedProductsNotExistSkus);
              this.modalIncludedProductsOpened = true;
            });

        return;
      }
    }

    this.productResource.getProductsLight(
      { sku: query ? query : null,
        'countryCode': SessionHelper.getCountry().code,
        'marketplace': MarketplaceHelper.getWebsiteMarketplace().code, pagination: true },
      { dontUseModel: true, blocking: false, isHydra: true, returnHydraMembers: true }
    )
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: any) => {
        this.products = response.filter((product: IProduct) => !!product.sku);
      });
  }

  public submit(event?: any): void {
    let message = this.translate('PAGE.DISCOUNT.FORM.CONFIRM_SAVE');
    if (this.form.get('customers').value.length < 1 && null === this.model.maximumUsagePerCustomer && null === this.model.maximumUsage) {
      message = `${this.translate('PAGE.DISCOUNT.FORM.ALERT_SAVE')}\n${message}`;
    }
    this.dialog.confirm(message)
      .then(() => {
        const body: IFormBody = this.prepareBody();

        if (!body.name) {
          this.validNameField.emit(false);

          return;
        }

        this.validNameField.emit(true);

        if (this.form.value.purchaseRange || this.form.value.minimumProduct) {
          this.discountCartResource.updateDiscountCart(this.model.type.id, {
            purchaseRange: this.form.value.purchaseRange,
            minimumProduct: this.form.value.minimumProduct
          }).takeUntil(this.destroyed$)
            .subscribe();
        }
        (<DiscountResource>this.resource).updateDiscount(this.state.params.id, body)
          .pipe(
            takeUntil(this.destroyed$)
          )
          .subscribe((response: any) => {
            this.snackbar.validate(this.translate('ALERTS.FORM.SAVED'));
            this.formNotifier.notifyFormSubmitted();

            if (event && event.redirect) {
              this.state.go(`${this.resource.routeName}.list`);

              return;
            }

            this.state.go(`${this.resource.routeName}.edit.restrictions`, { id: response.id });
          })
        ;
      })
    ;
  }

  public applyModal() {
    const newValue = [...this.form.get('customers').value];
    for (const id in this.customerToAdd) {
      if (Object.prototype.hasOwnProperty.call(this.customerToAdd, id)) {
        const isCheck = this.customerToAdd[id];
        if (isCheck) {
          const customerToAdd = this.customerExist.find((customer: ICustomer) => +customer.id === +id);
          if (customerToAdd && !newValue.find((customer: ICustomer) => +customer.id === +customerToAdd.id)) {
            newValue.push(customerToAdd);
          }
        }
      }
    }


    this.form.get('customers').setValue(newValue);
    this.modalOpened = false;
  }

  public applyIncludedProductsModal() {
    const newValue = [...this.form.get('includedProducts').value];
    for (const id in this.includedProductsToAdd) {
      if (Object.prototype.hasOwnProperty.call(this.includedProductsToAdd, id)) {
        const isCheck = this.includedProductsToAdd[id];
        if (isCheck) {
          const includedProductsToAdd = this.includedProductsExist.find((product: IProduct) => +product.id === +id);
          if (includedProductsToAdd && !newValue.find((product: IProduct) => +product.id === +includedProductsToAdd.id)) {
            newValue.push(includedProductsToAdd);
          }
        }
      }
    }


    this.form.get('includedProducts').setValue(newValue);
    this.modalIncludedProductsOpened = false;
  }

  private prepareBody(): any {
    const formValue: IDiscountFormGeneralModel = this.form.value;

    return {
      name: this.commonFields.name.value,
      description: this.commonFields.description.value,
      zendeskTicketId: this.commonFields.zendeskTicketId.value,
      minimumAmount: formValue.minimumAmount,
      maximumAmount: formValue.maximumAmount,
      combinable: formValue.combinable,
      excludeSales: formValue.excludeSales,
      includedProducts: formValue.includedProducts.length > 0 ? this.prepareIncludedProducts(formValue) : [],
      excludedProducts: formValue.excludedProducts.length > 0 ? this.prepareExcludedProducts(formValue) : [],
      includedCategories: formValue.includedCategories.length > 0 ? this.prepareIncludedCategories(formValue) : [],
      excludedCategories: formValue.excludedCategories.length > 0 ? this.prepareExcludedCategories(formValue) : [],
      customers: this.prepareCustomers(formValue),
    };
  }

  private prepareCustomers(formValue: IDiscountFormGeneralModel): string[] {
    if (formValue.customers) {
      return formValue.customers.map((customer: { id: string; label: string }): string => customer.id ? HydraHelper.buildIri(customer.id, 'customers') : '');
    }
  }

  private prepareIncludedProducts(formValue: IDiscountFormGeneralModel): string[] {
    if (formValue.includedProducts) {
      return formValue.includedProducts.map((product: { id: string; label: string }): string => product.id ? HydraHelper.buildIri(product.id, 'products') : '');
    }
  }

  private prepareExcludedProducts(formValue: IDiscountFormGeneralModel): string[] {
    if (formValue.excludedProducts) {
      return formValue.excludedProducts.map((product: { id: string; label: string }): string => product ? HydraHelper.buildIri(product, 'products') : '');
    }
  }

  private prepareIncludedCategories(formValue: IDiscountFormGeneralModel): string[] {
    if (formValue.includedCategories) {
      return formValue.includedCategories.map((category: { id: string }): string => HydraHelper.buildIri(category, 'categories'));
    }
  }

  private prepareExcludedCategories(formValue: IDiscountFormGeneralModel): string[] {
    if (formValue.excludedCategories) {
      return formValue.excludedCategories.map((category: { id: string }): string => HydraHelper.buildIri(category, 'categories'));
    }
  }

  private multipleMailsParser(query: string): string[] {
    const mails = [];
    const regex = new RegExp('([^\\x00-\\x20\\x22\\x28\\x29\\x2c\\x2e\\x3a-\\x3c\\x3e\\x40\\x5b-\\x5d\\x7f-\\xff]+' +
      '|\\x22([^\\x0d\\x22\\x5c\\x80-\\xff]' +
      '|\\x5c[\\x00-\\x7f])*\\x22)(\\x2e([^\\x00-\\x20\\x22\\x28\\x29\\x2c\\x2e\\x3a-\\x3c\\x3e\\x40\\x5b-\\x5d\\x7f-\\xff]+' +
      '|\\x22([^\\x0d\\x22\\x5c\\x80-\\xff]' +
      '|\\x5c[\\x00-\\x7f])*\\x22))*\\x40([^\\x00-\\x20\\x22\\x28\\x29\\x2c\\x2e\\x3a-\\x3c\\x3e\\x40\\x5b-\\x5d\\x7f-\\xff]+' +
      '|\\x5b([^\\x0d\\x5b-\\x5d\\x80-\\xff]' +
      '|\\x5c[\\x00-\\x7f])*\\x5d)(\\x2e([^\\x00-\\x20\\x22\\x28\\x29\\x2c\\x2e\\x3a-\\x3c\\x3e\\x40\\x5b-\\x5d\\x7f-\\xff]+' +
      '|\\x5b([^\\x0d\\x5b-\\x5d\\x80-\\xff]' +
      '|\\x5c[\\x00-\\x7f])*\\x5d))*', 'gm');
    let match;

    while ((match = regex.exec(query)) !== null) {
      if (match.index === regex.lastIndex) {
        regex.lastIndex++;
      }

      mails.push(match[0]);
    }

    return mails.filter((value: any, index: number, self: any[]) => {
      return self.indexOf(value) === index;
    });
  }

  private multipleSkusParser(query: string): string[] {
    const skus = [];
    const regex = new RegExp('([A-Z0-9a-z\\+\\-_]+)(?:,|;)?', 'gm');
    let match;

    while ((match = regex.exec(query)) !== null) {
      if (match.index === regex.lastIndex) {
        regex.lastIndex++;
      }

      skus.push(match[1]);
    }

    return skus.filter((value: any, index: number, self: any[]) => {
      return self.indexOf(value) === index;
    });
  }
}
