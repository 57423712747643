import {Component, EventEmitter, Inject, Input, OnInit, Output, TemplateRef} from '@angular/core';
import { AbstractFormComponent } from '@components/generic/Form/abstract-form.component';
import { AuthService } from '@services/auth.service';
import {IImport, IImportItem} from '@components/generic/import-v2/models/import-field.model';
import { SnackbarService } from '@components/snackbar/snackbar.service';
import {AbstractResource} from '@resources';
import {ImportResource} from '@components/generic/import-v2/import.resource';

@Component({
  selector: 'app-import-v2',
  template: require('./import-v2.component.html'),
  providers: [
    { provide: AbstractResource, useClass: ImportResource },
  ],
})
export class ImportV2Component extends AbstractFormComponent implements OnInit {
  public status: string = null;
  public importModel: IImport = null;

  @Input() public retrieveImportModel: () => Promise<IImport>;
  @Input() public itemTemplate?: TemplateRef<any> = null;
  @Input() public beforeNeedManualValidationTemplate?: TemplateRef<any> = null;
  @Input() public errorTemplate?: TemplateRef<any> = null;
  @Input() public waitingImportTemplate?: TemplateRef<any> = null;
  @Input() public finishedSuccessTemplate?: TemplateRef<any> = null;
  @Output() public onCancelImport: EventEmitter<any> = new EventEmitter();

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
    private snackbar: SnackbarService
  ) {
    super($translate, authService, resource, state);
  }

  ngOnInit(): void {
    this.retrieveImportModel()
      .then((importModel: IImport) => {
        this.importModel = importModel;
        this.status = importModel.status;
      })
      .catch(() => {
        this.status = 'error';
      });
  }

  validateImport() {
    if (this.isImportModelValid()) {
      this.resource
        .submitImportValidation(this.importModel)
        .takeUntil(this.destroyed$)
        .subscribe(
          (response: IImport) => {
            this.status = response.status;

            if ('waiting_import' === response.status) {
              this.snackbar.inform(this.translate('PAGE.IMPORT.MANUAL_VALIDATION.SNACKBAR.WAITING_IMPORT'));
            } else {
              this.importModel = response;
              this.snackbar.warn(this.translate('PAGE.IMPORT.MANUAL_VALIDATION.SNACKBAR.NEED_REVALIDATION'));
              this.state.reload();
            }
          },
          (error: any) => {
            this.status = 'error';
          }
        );
    } else {
      this.snackbar.warn(this.translate('PAGE.IMPORT.MANUAL_VALIDATION.SNACKBAR.INVALID_FORM'));
    }
  }

  isImportModelValid(): boolean {
    let response = true;

    this.importModel.data.forEach((item: IImportItem) => {
      if (!item.valid) {
        response = false;
      }
    });

    return response;
  }

  cancelImport() {
    this.onCancelImport.emit();
  }

  reloadPage(): void {
    this.ngOnInit();
  }
}
