'use strict'

import { HTML_5_MODE } from '../constants'
import { DASHBOARD_REVENUES } from '@constants/route.constants'
import { SessionHelper as SessionHelperStatics } from '@helpers'
import attributeRoutes from '@components/attribute'
import attributeFamilyRoutes from '@components/attribute-family'
import attributeSetRoutes from '@components/attribute-set'
import bannerRoutes from '@components/banner'
import catalogsRoutes from '@components/catalogs'
import categoriesRoutes from '@components/categories'
import carrierFamilyRoutes from '@components/carrierFamily'
import carrierRoutes from '@components/carrier'
import categoriesMarketplace from '@components/categories-marketplace'
import cmsRoutes from '@components/cms'
import commercialOperationsRoutes from '@components/commercial-operations'
import customerRoutes from '@components/customer'
import dashboardRoutes from '@components/dashboard'
import loginRoutes from '@components/login'
import manufacturerRoutes from '@components/manufacturer'
import OrderCollectionRoutes from '@components/order-collection'
import paymentCarrierIconsRoutes from '@components/payment-carrier-icons'
import redirectsRoutes from '@components/redirects'
import refundsRoutes from '@components/transactions/refunds'
import footerRoutes from '@components/footer'
import reverseRoutes from '@components/reverse'
import tabRoutes from '@components/tab'
import usersRoutes from '@components/users'
import warehousesRoutes from '@components/warehouses'
import warrantyRoutes from '@components/warranty'
import exportLogisticsRoutes from '@components/export-logistics'
import taskManagerRoutes from '@components/task-manager/task-type'
import trackingSalesRoutes from '@components/tracking-sales'
import orderManagerRoutes from '@components/order-manager'
import serviceRoutes from '@components/service'
import shippingPriceRoutes from '@components/shipping-price'
import stockRoutes from '@components/stock'
import stockMovementRoutes from '@components/stock-movement'
import stockShootingRoutes from '@components/stock-shooting'
import superProductRoutes from '@components/super-product'
import discountRoutes from '@components/discount'
import productRoutes from '@components/product/product.routes'
import sparePartRoutes from '@components/spare-part/spare-part.routes'
import savRoutes from '@components/sav/sav.routes'
import estimateRoutes from '@components/estimate/estimate.routes'
import qualityExportRoutes from '@components/quality-export'
import salesLogExportRoutes from '@components/sales-log-export'
import marketplacesConfigureRoutes from '@components/marketplaces-configure'
import orderRoutes from '@components/order/order.routes'
import websiteCategoryRoutes from '@components/website-category/website-category.routes'
import websiteModuleRoutes from '@components/website-module/website-module.routes'
import websiteHomepageRoutes from '@components/website-homepage/website-homepage.routes'
import grcRoutes from '@components/grc/grc.routes'
import commitmentRoutes from '@components/commitment/commitment.routes'
import unpairedRoutes from "@components/unpaired/unpaired.routes"
import roomRoutes from "@components/room/room.routes"
import addressingRoutes from "@components/addressing/addressing.routes"
import logisticWaveRoutes from "@components/logistic-wave/logistic-wave.routes.ts"
import litigationRoutes from "@components/litigation/litigation.routes.ts"
import oneyOpcRoutes from "@components/oney-opc/oney-opc.routes.ts"
import eavAttributeRoutes from "@components/eav-attribute/eav-attribute.routes.ts"
import glossaryRoutes from "@components/glossary/glossary.routes.ts"
import supplierRoutes from "@components/supplier/supplier.routes.ts"
import proformaInvoiceRoutes from "@components/proforma-invoice/proforma-invoice.routes.ts"
import containerRoutes from "@components/container/container.routes.ts"

angular
  .module 'ammsFrontendApp'
  .config [
    '$locationProvider'
    '$stateProvider'
    '$urlRouterProvider'
    ($locationProvider, $stateProvider, $urlRouterProvider) ->
      $locationProvider.html5Mode(HTML_5_MODE)
      $urlRouterProvider.otherwise('/')
      $stateProvider
        .state 'layout',
          abstract: true
          templateUrl: require('views/main/layout.html')
        .state('walisoft',
          parent: 'layout',
          abstract: true,
          views: {
            'footer': {
              templateUrl: require('views/partials/footer.html')
            }
          },
          breadcrumb: {
            label: 'BREADCRUMB.DASHBOARD',
            stateName: DASHBOARD_REVENUES
          },
          data: {
            secure: true
          }
        )

        .state 'walipush',
          parent: 'walisoft'
          abstract: true

        .state 'product',
          parent: 'walipush'
          abstract: true
          breadcrumb:
            label: 'BREADCRUMB.PRODUCT'
            stateName: 'product.list'
        .state 'product.list',
          url: '/products?page&byPage&catalog&superProduct&sku&reference&marketplaces[]&hidden&status&hasEan&hasLogistic&packageType&stockMin&stockMax&orderBy&direction&currentTab'
          views:
            'page@layout':
              controller: 'ProductListCtrl'
              controllerAs: 'list'
              templateUrl: require('views/products/list/list.html')
          data:
            sidebarNav: 'walipush'
            sidebarSubNav: 'product'
            reloadChangeCountry: 'product.list'
            title: 'PAGE.PRODUCT.LIST.HEADER.TITLE'

        .state 'category',
          parent: 'walipush'
          abstract: true
          breadcrumb:
            label: 'BREADCRUMB.CATEGORY'
            stateName: 'category.list'
        .state 'category.list',
          url: '/categories_old'
          views:
            'page@layout':
              template: '<app-category-list></app-category-list>'
          data:
            sidebarNav: 'walipush'
            sidebarSubNav: 'category'
            reloadChangeCountry: 'category.list'
            title: 'PAGE.CATEGORY.LIST.TITLE'
        .state 'category.new',
          url: '/categories/{categoryType:universe|shelf}/new/'
          breadcrumb:
            label: 'BREADCRUMB.NEW'
          views:
            'page@layout':
              template: '<app-category-new></app-category-new>'
          data:
            sidebarNav: 'walipush'
            sidebarSubNav: 'category'
            reloadChangeCountry: 'category.list'
            title: 'PAGE.CATEGORY.NEW.TITLE'

        .state('category.edit',
          url: '/categories/:id/edit'
          breadcrumb:
            label: 'BREADCRUMB.EDIT'
          views:
            'page@layout':
              template: '<app-category-edit></app-category-edit>'
          data:
            sidebarNav: 'walipush'
            sidebarSubNav: 'category'
            reloadChangeCountry: 'category.list'
            reloadChangeCountrySearchParam: 'id'
            title: 'PAGE.CATEGORY.EDIT.TITLE'
        )

        .state('order_old',
          parent: 'walisoft'
          abstract: true
          breadcrumb: {
            label: 'BREADCRUMB.ORDER.LIST'
            stateName: 'order.list'
          }
        )
        .state 'order_old.new',
          url: '/orders-old/new'
          params: {
            marketplace: null,
            orderType: null
          }
          breadcrumb: {
            label: 'BREADCRUMB.NEW'
          }
          views: {
            'page@layout':
              controller: 'OrderNewCtrl'
              templateUrl: require('views/orders/new.html')
          }
          data: {
            sidebarNav: 'order'
            reloadChangeCountry: 'order.list'
            title: 'PAGE.ORDER.NEW.HEADER.TITLE'
          }
        .state 'website',
          parent: 'walisoft'
          abstract: true

        .state 'notations',
          parent: 'website'
          abstract: true
          breadcrumb:
            label: 'BREADCRUMB.NOTATIONS'
            stateName: 'notations.list'
        .state 'notations.list',
          url: '/notations'
          views:
            'page@layout':
              template: '<app-notation-list></app-notation-list>'
          data:
            sidebarNav: 'website'
            sidebarSubNav: 'notations'
            reloadChangeCountry: 'notations.list'
            title: 'PAGE.NOTATIONS.LIST.TITLE'
  ]
  .run [
    '$rootScope'
    '$state'
    '$stateParams'
    'ProductResource'
    ($rootScope, $state, $stateParams, ProductResource) ->
      $rootScope.$on '$stateCountryChangeSuccess', ->
        newState = $state.current.data.reloadChangeCountry
        params = {}
        pendingRequest = false

        if newState?
          SessionHelperStatics.removeFiltersForPage($state.$current.parent.self.name)
          newStateParam = $state.current.data.reloadChangeCountrySearchParam

          if ($stateParams.hasOwnProperty('translations.locale'))
            params['translations.locale'] = SessionHelperStatics.getLocale()

          if newStateParam? && $state.current.name.includes('product.edit')
            pendingRequest = true

          if newStateParam? && 'product.edit' isnt $state.current.name
            params[newStateParam] = $stateParams[newStateParam]

        else
          newState = DASHBOARD_REVENUES

        # TODO: adapt this when migrating to angular router
        if ('order_new.list' == $state.current.name)
          $state.go 'order_new.list', {
            page: 1,
            byPage: 15,
            order: 'DESC',
            orderType: 'all',
            'countries[]': SessionHelperStatics.getCountry().code,
          }, { reload: true, notify: true, inherit: false }

          return

        if (!pendingRequest)
          $state.go newState, params, { reload: true, inherit: true, notify: true }
  ]
  .run [
    '$rootScope'
    '$window'
    ($rootScope, $window) ->
      $rootScope.$on('$locationChangeSuccess', (event, toUrl, fromUrl) ->
        if (toUrl != fromUrl)
          $window.scrollTo(0, 0)
      )
  ]
  .config(attributeRoutes)
  .config(attributeFamilyRoutes)
  .config(attributeSetRoutes)
  .config(bannerRoutes)
  .config(carrierFamilyRoutes)
  .config(carrierRoutes)
  .config(catalogsRoutes)
  .config(categoriesRoutes)
  .config(categoriesMarketplace)
  .config(cmsRoutes)
  .config(commercialOperationsRoutes)
  .config(customerRoutes)
  .config(dashboardRoutes)
  .config(exportLogisticsRoutes)
  .config(loginRoutes)
  .config(manufacturerRoutes)
  .config(OrderCollectionRoutes)
  .config(paymentCarrierIconsRoutes)
  .config(redirectsRoutes)
  .config(refundsRoutes)
  .config(footerRoutes)
  .config(reverseRoutes)
  .config(savRoutes)
  .config(estimateRoutes)
  .config(serviceRoutes)
  .config(shippingPriceRoutes)
  .config(stockRoutes)
  .config(stockMovementRoutes)
  .config(stockShootingRoutes)
  .config(superProductRoutes)
  .config(sparePartRoutes)
  .config(tabRoutes)
  .config(taskManagerRoutes)
  .config(trackingSalesRoutes)
  .config(usersRoutes)
  .config(warehousesRoutes)
  .config(warrantyRoutes)
  .config(discountRoutes)
  .config(productRoutes)
  .config(qualityExportRoutes)
  .config(salesLogExportRoutes)
  .config(marketplacesConfigureRoutes)
  .config(orderRoutes)
  .config(orderManagerRoutes)
  .config(websiteCategoryRoutes)
  .config(websiteModuleRoutes)
  .config(websiteHomepageRoutes)
  .config(grcRoutes)
  .config(commitmentRoutes)
  .config(unpairedRoutes)
  .config(roomRoutes)
  .config(addressingRoutes)
  .config(logisticWaveRoutes)
  .config(litigationRoutes)
  .config(oneyOpcRoutes)
  .config(eavAttributeRoutes)
  .config(glossaryRoutes)
  .config(supplierRoutes)
  .config(proformaInvoiceRoutes)
  .config(containerRoutes)
