import {
  IImport,
  IImportErrors,
  IImportItem,
  IImportProcess
} from '@components/generic/import-v2/models/import-field.model';
import {
  ProductImportErrors,
  ProductImportProductItemFieldModel,
  ProductImportProductItemModel
} from '@components/product/models/product-import.model';

export class SuperProductImportModel implements IImport {
  public status: string;
  public process: IImportProcess;
  public data: SuperProductImportItemModel[];
  public errors: IImportErrors;

  constructor(model: IImport) {
    this.status = model.status;
    this.process = model.process;
    this.data = model.data.map((element: ISuperProductImportItem) => new SuperProductImportItemModel(element));
    this.errors = new ProductImportErrors(model.errors);
  }
}

export class SuperProductImportItemModel implements ISuperProductImportItem {
  public sku: string;
  public valid: boolean;
  public validOnLoad: boolean;
  public fields: ProductImportProductItemFieldModel[];

  constructor(model: ISuperProductImportItem) {
    this.sku = model.sku;
    this.valid = model.valid;
    this.validOnLoad = model.valid;
    this.fields = model.fields.map(element => new ProductImportProductItemFieldModel(element));
  }

  getIdentifier(): string {
    return this.sku;
  }
}

interface ISuperProductImportItem extends IImportItem {
  sku: string;
}
