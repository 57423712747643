import {
  IImport,
  IImportErrors,
  IImportItem, IImportItemField, IImportItemFieldValidation,
  IImportProcess, ImportErrorsModel
} from '@components/generic/import-v2/models/import-field.model';

export class ProformaInvoiceImportModel implements IImport {
  public status: string;
  public process: IImportProcess;
  public data: ProformaInvoiceImportItemModel[];
  public errors: IImportErrors;

  constructor(model: IImport) {
    this.status = model.status;
    this.process = model.process;
    this.data = model.data.map((element: IProformaInvoiceImportItem) => new ProformaInvoiceImportItemModel(element));
    this.errors = new ImportErrorsModel(model.errors);
  }
}

export class ProformaInvoiceImportItemModel implements IProformaInvoiceImportItem {
  public identifier: string;
  public valid: boolean;
  public validOnLoad: boolean;
  public fields: ProformaInvoiceImportItemFieldModel[];

  constructor(model: IProformaInvoiceImportItem) {
    this.identifier = model.identifier;
    this.valid = model.valid;
    this.validOnLoad = model.valid;
    this.fields = model.fields;
  }

  getIdentifier(): string {
    return this.identifier;
  }
}

export class ProformaInvoiceImportItemFieldModel implements IImportItemField {
  id: string;
  value: string;
  valueOnLoad: string;
  validation: IImportItemFieldValidation;

  constructor(model: IImportItemField) {
    this.id = model.id;
    this.value = model.value;
    this.valueOnLoad = model.value;
    this.validation = model.validation;
    this.validation.validOnLoad = model.validation.valid;
  }
}

interface IProformaInvoiceImportItem extends IImportItem {
  identifier: string;
}
